import { Injectable } from '@angular/core';
import { ApiConfig } from '../config/ApiConfig';
import { Solicitud } from '../model/Solicitud';
import { HttpClient } from '@angular/common/http';
import { Message } from '../model/Message';
import { ConsultaTicket } from '../model/ConsultaTicket';

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {

  private url : string;

  
  constructor(private http : HttpClient,
              private api : ApiConfig
              ) {
          this.url = "";
         
               }


  registrar(solicitud : Solicitud){
    this.url = this.api.urlPqr+"/api/pqr/radicar/webApp";
    return this.http.post<Message>(this.url,solicitud);
  }

  getConsultarEstadoTicket(numTicket : string, numDocu : string){
    this.url = this.api.urlPqr+"/api/pqr/consultar/"+numTicket+"/"+numDocu;
    return this.http.get<ConsultaTicket>(this.url);
  }
}
