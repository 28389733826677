import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '../config/ApiConfig';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private url : string;

  constructor(private http : HttpClient,
              private api : ApiConfig) {

    this.url = "";
  }

  consultarUsuario(tipoUsuarioId : number, numDocumento : string, numContrato : string,tipoDocumento : string){
    this.url = this.api.urlPqr+"/api/pqr/consultarUsuario/"+tipoUsuarioId+"/"+numDocumento+"/"+numContrato+"/"+tipoDocumento;
    return this.http.get<Boolean>(this.url);
  }
  
}
