import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '../config/ApiConfig';
import { Regional } from '../model/Regional';
import { Dependencia } from '../model/Dependencia';
import { Clasificacion1 } from '../model/Clasifiacion1';


@Injectable({
  providedIn: 'root'
})
export class RegionalService {

  private url : string;

  constructor(private http : HttpClient,
              private api : ApiConfig) {

    this.url = "";

   }

   getReginoles(){
    this.url = this.api.urlPqr+"/api/pqr/regional";
    return this.http.get<Array<Regional>>(this.url);
  }

  getDependencia(idRegional : number){
    this.url = this.api.urlPqr+"/api/pqr/dependencia/"+idRegional;
    return this.http.get<Array<Dependencia>>(this.url);
   }

   getClasificacion(idRegional:number, idDependencia:number){
     this.url = this.api.urlPqr+"/api/pqr/clasificacion/"+idDependencia+"/"+idRegional;
     return this.http.get<Array<Clasificacion1>>(this.url);
   }

   
}
