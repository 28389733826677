import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/components/common/selectitem';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { Solicitud } from '../share/model/Solicitud';
import { Ciudad } from '../share/model/Ciudad';
import { SitioOcurrencia } from '../share/model/SitioOcurrencia';
import { CargueFile } from '../share/model/CargueFile';
import { MedioIngreso } from '../share/model/MedioIngreso';
import { TipoUsuario } from '../share/model/TipoUsuario';
import { Regional } from '../share/model/Regional';
import { Dependencia } from '../share/model/Dependencia';
import { Clasificacion1 } from '../share/model/Clasifiacion1';
import { UtilitiesService } from '../share/service/utilities.service';
import { UsuarioService } from '../share/service/usuario.service';
import { RegionalService } from '../share/service/regional.service';
import { SolicitudService } from '../share/service/solicitud.service';
import { TipoRequerimiento } from '../share/model/TipoRequerimiento';
import { TipoDocumento } from '../share/model/TipoDocumento';
import { Departamento } from '../share/model/Departamento';
import { CanalRespuesta } from '../share/model/CanalRespuesta';
import { RecaptchaService } from '../share/service/recaptcha.service';
import { CaptchaRequest } from '../share/model/CaptchaRequest';
import { IfStmt, isNgTemplate } from '@angular/compiler';
import { AnyWrapper } from '../share/model/AnyWrapper';
import { Causal } from '../share/model/Causal';
import { SourceListMap } from 'source-list-map';
import { stringify } from 'querystring';
import { ConsultaTicket } from '../share/model/ConsultaTicket';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {
  
  validacion : Array<Boolean>;

  
  solicitud : Solicitud;
  numDocumento : string="";
  nombre : string="";
  apellido : string="";
  correo: string="";
  dirCorrespondencia : string="";
  telfijo : string ="";
  celular : string="";
  medioIngresoFlag = false;
  nombreRadica : string = "";
  documentoRadica : string ="";
  servicioInconformes : any;
  departamentoData : any;
  uplo: File;
  myfile : any;
  //departamentos: SelectItem[];
  departamentos: Array<Departamento>;
  departamento : any;
  //tipoDocumentos: SelectItem[];
  tipoDocumentos: Array<TipoDocumento>;
  tipoDocumento : any;
  //tipoUsuarios: SelectItem[];
  tipoUsuarios: Array<TipoUsuario>;
  tipoUsuario : any;
  ciudadesList : Array<Ciudad>;
  //ciudades: SelectItem[];
  ciudades: Array<Ciudad>;
  ciudad : any;
  //tipoComentarios: SelectItem[];
  tipoComentarios: Array<TipoRequerimiento>;
  tipoComentario : any;
  //medioRecibirInformaciones : SelectItem[];
  medioRecibirInformaciones: Array<CanalRespuesta>;
  medioRecibirInformacion : any;
  fechaHoraEchos : any;
  nombrePropio : boolean;
  ciudadesEchos : SelectItem[];
  ciudadEchos : any;
  sitioOcurrencias : SelectItem[];
  sitioOcurrencia : any;
  sitioOcurrenciaList : Array<SitioOcurrencia>;
  observacion : string = "";
  uploadedFiles: Array<CargueFile>;
  cargueTmp : CargueFile;
  serviciosInconformes : SelectItem[];
  descripcionMedioRecibirInformacion : string="";
  medioIngresos : Array<SelectItem>;
  medioIngresoList : Array<MedioIngreso>;
  numContrato : string = "0";
  tipoUsu : TipoUsuario;
  usuarioExistente : Boolean = false;
  regionales : Array<Regional>;
  dependencias : Array<Dependencia>;
  regionalId : number = -1;
  dependenciaId : number =-1;
  clasificaciones : Array<Clasificacion1>;
  clasificacionId : number =-1;
  medioIngreso : any = "PÁGINA WEB";
  finRadicacion : Boolean = false;
  mensaje : string="";
  maxDate : Date;
  activoPaso : boolean = false;
  valueHabeas : boolean = false;
  causalList : Array<Causal>;
  causal : Array<Causal>;
  texto : string;
  activaConsulta : Boolean = false;
  numDocuConsulta : string = "";
  numTicketConsulta : string = "";
  consultaResultado : Boolean = false;
  estadoTicket : ConsultaTicket;
  nombreSitioOcurrencia : string;
  indicador : Boolean = false;


  constructor(
    private messageService: MessageService,
    private utilitiesService : UtilitiesService,
    private usuarioService : UsuarioService,
    private regionalService : RegionalService,
    private solicitudService : SolicitudService,
    private confirmationService: ConfirmationService,
    private recapchaService : RecaptchaService,
    
  ) {}

  ngOnInit() {
    this.inicializarData();
  }

  addSingle(mensaje : string, seve : string) {
    this.clear();
    this.messageService.add({severity:seve, summary:'Detalle del registro', detail:mensaje});
   }
   
  clear() {
    this.messageService.clear();
  }

  datoPC(){
    this.servicioInconformes();
  }
  
  convertAnyWrapper(id : any,identificador : number){
     
    let anyWrapper : AnyWrapper;
    switch(identificador){
      //Si el anywrapper es = 1 -- TipoUsuario
      case 1:
      anyWrapper = new AnyWrapper(id,this.tipoUsuarios[id-1].tipo);
         break;
      //Identificador = 2 -- TipoDocumento   
      case 2:
      this.tipoDocumentos.forEach(tipoDocumento => {
          if(tipoDocumento.tipoDoc == id){
            anyWrapper = new AnyWrapper(id,tipoDocumento.descDoc); 
          }
      });
      break;
      //Identificador = 3 -- Departamento   
      case 3: 
       this.departamentos.forEach(departa => {
         if(departa.iddepartamento == id){
          anyWrapper = new AnyWrapper(id,departa.nombre); 
         }
       });
        break;
      //Identificador = 4 -- Ciudad  
      case 4: 
        this.ciudades.forEach(ciud => {
          if(ciud.idciudad == id){
            anyWrapper = new AnyWrapper(id,ciud.nombre);
          }
        });
      break;
      //Identificador = 5 -- Medio  
      case 5: 
      this.medioRecibirInformaciones.forEach(medioRecibir => {
        if(medioRecibir.idcanalrespuesta == id){
          anyWrapper = new AnyWrapper(id,medioRecibir.nombre);
        }
      });
     
        break;
      //Identificador = 6 -- TipoComentario  
      case 6: 
      this.tipoComentarios.forEach(tipocomen => {
        if(tipocomen.idtiporequerimiento == id){
          anyWrapper = new AnyWrapper(id,tipocomen.nombre);
        }
      });
        break;

        
     }

     return anyWrapper;
  }
  

  public inicializarData(){
  
    this.ciudadesList = new Array<Ciudad>();
    //this.tipoComentarios = new Array<SelectItem>();
    this.tipoComentarios = new Array<TipoRequerimiento>();
    this.nombrePropio = true;
    this.sitioOcurrenciaList = new Array<SitioOcurrencia>();
    //this.tipoDocumentos = new Array<SelectItem>();   
    this.tipoDocumentos = new Array<TipoDocumento>();
    //this.departamentos = new Array<SelectItem>();
    this.departamentos = new Array<Departamento>();
    //this.tipoUsuarios = new Array<SelectItem>();
    this.tipoUsuarios = new Array<TipoUsuario>();
    //this.medioRecibirInformaciones = new Array<SelectItem>();
    this.medioRecibirInformaciones = new Array<CanalRespuesta>();
    this.uploadedFiles = new Array<CargueFile>();
    this.servicioInconformes = new Array<any>();
    this.serviciosInconformes = new Array<SelectItem>();
    this.medioIngresos = new Array<SelectItem>();
    this.tipoUsu = new TipoUsuario();
    this.dependencias = new Array<Dependencia>();
    this.clasificaciones = new Array<Clasificacion1>();
    this.maxDate = new Date();
    this.causal = new Array<Causal>();

    this.desactivar();

    this.utilitiesService.getMedioCanalIngreso().subscribe(
      (success) =>{
        this.createMedioIngreso(success);
      },(err) =>{
        console.log(err);
      }
    )

    this.regionalService.getReginoles().subscribe(
      (success)=>{
        this.regionales = success;
      },(err)=>{
        console.log(err);
      }
    )

    this.utilitiesService.getTipoRequerimiento().subscribe(
      (success) =>{
       //this.createTipoComentario(success);
       this.tipoComentarios = success;
      },
      (error)=>{
        console.log(error);
      }
    );

    this.utilitiesService.getTipoDocumento().subscribe(
      (success) =>{
       //this.createTipoDocumento(success);
       this.tipoDocumentos = success;
      },
      (err) =>{
        console.log(err);
      }
    );
   
    this.utilitiesService.getDepartamentos().subscribe(
      (success) =>{
        this.departamentoData = success;
        //this.createDepartamento(success);
        this.departamentos = success;
      },
      (err) =>{
        console.log(err);
      }
    );

    this.utilitiesService.getCanalRespuesta().subscribe(
      (success) =>{
        //this.createCanalRespuesta(success)
        this.medioRecibirInformaciones = success;
      },
      (err) =>{
        console.log(err);
      }
    )

    this.utilitiesService.getTipoUsuario().subscribe(
      (success) =>{
       //this.createTipoUsuario(success);
       this.tipoUsuarios = success;
      },
      (err) =>{
        console.log(err);
      }
    )


    this.nombreRadica  = "";
    this.documentoRadica  ="";
    this.observacion  = "";
    this.numContrato = "0";
    this.regionalId  = -1;
    this.dependenciaId =-1;
    this.clasificacionId =-1;
  }

  /*createTipoComentario(success : Array<TipoRequerimiento>){
    let seleccion : SelectItem;

    success.forEach(tipoComentario=>{
       seleccion = {label:tipoComentario.nombre,value:{id:tipoComentario.idtiporequerimiento,name:tipoComentario.nombre}};
       this.tipoComentarios.push(seleccion);
    })
  }*/

  /*createTipoDocumento(success : Array<TipoDocumento>){
    let seleccion : SelectItem;
    success.forEach(tipoDocumento=>{
       seleccion = {label:tipoDocumento.descDoc,value:{id:tipoDocumento.tipoDoc,name:tipoDocumento.descDoc}};
       this.tipoDocumentos.push(seleccion);
    })
  }*/

  /*createDepartamento(success : Array<Departamento>){
    let seleccion : SelectItem;
    
    success.forEach(departamento=>{
       seleccion = {label:departamento.nombre,value:{id:departamento.iddepartamento,name:departamento.nombre}};
       this.departamentos.push(seleccion);
    })
  }*/

  /*createCanalRespuesta(success : Array<CanalRespuesta>){
    let seleccion : SelectItem;
    
    success.forEach(canal=>{
       seleccion = {label:canal.nombre,value:{id:canal.idcanalrespuesta,name:canal.nombre}};
       this.medioRecibirInformaciones.push(seleccion);
    })
  }*/

  /*createTipoUsuario(success : Array<TipoUsuario>){
    let seleccion : SelectItem;
    
    success.forEach(tipoUsuario=>{
       seleccion = {label:tipoUsuario.tipo,value:{id:tipoUsuario.id,name:tipoUsuario.tipo}};
       this.tipoUsuarios.push(seleccion);
    })
  }*/
   
  createMedioIngreso(success){

    this.medioIngresoList = success;
    
    let seleccion : SelectItem;

     success.forEach(medioIngreso =>{
      seleccion = {label:medioIngreso.nombre,value:{id:medioIngreso.idcanalsolicitud,name:medioIngreso.nombre}};
      this.medioIngresos.push(seleccion);
     });

  }

  conseguirCausales(item: any){
      this.cambioEstado(16);
      this.utilitiesService.getCausales(item.value.id, this.regionalId.toString(), this.dependenciaId.toString()).subscribe(
      (success) => {
        item.causales = success;
      },
      (err) => {
        console.log(err);
      }
    )
  }

  public filtroCiudad(){
   this.ciudades = new Array<Ciudad>();

   /*let seleccion : SelectItem;
   seleccion ={label:'Ciudad', value:null};
   this.ciudades.push(seleccion);*/
   
   this.utilitiesService.getCiudadByDepartamento(this.departamento).subscribe(
     (success) =>{
       /*success.forEach(ciudad =>{
        seleccion = {label:ciudad.nombre,value:{id:ciudad.idciudad,name:ciudad.nombre}};
        this.ciudades.push(seleccion);
       });*/
       this.ciudades = success;
     },
     (err) =>{
       console.log(err);
     }
   )

  }

  public filtroSitioOcurrencia(){
    this.sitioOcurrencias = new Array<SelectItem>();

    let seleccion : SelectItem;
    seleccion ={label:'Sitio Ocurrencia', value:null};
    this.sitioOcurrencias.push(seleccion);

    this.sitioOcurrenciaList.forEach(sitio => {
      
      if(sitio.idC == this.ciudadEchos.id){
        seleccion = {label:sitio.nombre,value:{id:sitio.id,name:sitio.nombre}};
        this.sitioOcurrencias.push(seleccion);
      }
    });

  }

  onUpload(event){
   
    var files = event.files[0];
    this.cargueTmp = new CargueFile();
    this.cargueTmp.nombreFile = files.name;
    this.cargueTmp.extensionFile=files.type;  
    var reader = new FileReader();
    reader.onload =this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
    
}

  _handleReaderLoaded(readerEvt) {
   
    var binaryString = readerEvt.target.result;
    this.cargueTmp.tipoAdjunto = 1;
    this.cargueTmp.base64file = btoa(binaryString);
    
    if(this.uploadedFiles.length<=2){
      this.uploadedFiles.push(this.cargueTmp);
    }else{
       this.addSingle("Ya adjunto los 3 archivos permitidos","warn");
    }
    
  
  }
  

   cargarSolicitud(){
    this.solicitud = new Solicitud();
    this.solicitud.tipoUsuario = this.convertAnyWrapper(this.tipoUsuario,1);
    this.solicitud.tipoDocumento = this.convertAnyWrapper(this.tipoDocumento,2);
    this.solicitud.numDocumento = this.numDocumento.trim();
    this.solicitud.nombre = this.nombre;
    this.solicitud.apellido = this.apellido;
    this.solicitud.correo = this.correo.trim();
    this.solicitud.dirCorrespondecia = this.dirCorrespondencia;
    this.solicitud.departamento = this.convertAnyWrapper(this.departamento,3);
    if(this.ciudad != undefined){
      this.solicitud.ciudad = this.convertAnyWrapper(this.ciudad,4);
    }
    this.solicitud.telFijo = this.telfijo.trim();
    this.solicitud.celular = this.celular.trim();
    this.solicitud.canalRespuesta = this.convertAnyWrapper(this.medioRecibirInformacion,5);
    this.solicitud.tipoComentario = this.convertAnyWrapper(this.tipoComentario,6);
    this.solicitud.nombrePropio = this.nombrePropio;
    this.solicitud.fechaHoraEchos = this.fechaHoraEchos;
    this.solicitud.ciudadEchos = this.ciudadEchos;
    this.solicitud.servicioInconforme = this.servicioInconformes;
    this.solicitud.observacion = (this.addObservacion()+" "+this.observacion);
    this.solicitud.archivoCargados = this.uploadedFiles;
    this.solicitud.descripcionMedioRecibirInformacion = this.descripcionMedioRecibirInformacion;
    this.solicitud.numContrato = (this.tipoUsuario == 1 ? this.numContrato.trim() : this.tipoUsuario == 2 ? "0" : "0");
    this.solicitud.regionalId = this.regionalId;
    this.solicitud.dependenciaId = this.dependenciaId;

    this.medioIngreso = {
      id : 6,
      name : "PÁGINA WEB"
   };

    this.solicitud.medioIngreso = this.medioIngreso;

  }

  registrar(){

   //if(this.activoPaso){
    if(this.servicioInconformes.length > 0){
      this.cargarSolicitud();
      console.log(this.solicitud)
      this.solicitudService.registrar(this.solicitud).subscribe(
      (success)=>{
        this.mensaje= "Registro realizado correctamente con el "+success.texto;
        this.addSingle(this.mensaje,"success"); 
        this.inicializarData(); 
        this.finRadicacion=true;
      },(err)=>{
        console.log(err);
        this.addSingle(err.error.texto,"error"); 
      }
      )
    }else{
      this.addSingle("Seleccione el servicio con el cual esta inconforme","warn");
    }
  /*}else{
    this.addSingle("Confirme reCaptcha","warn");
  }*/ 
    
    
    
    
  }

  cambioEstado(id : number){
    this.validacion[id] = true;
    if(id == 0 ){
      if(this.tipoUsuario == "1"){
        this.tipoUsu.id = 1;
        this.tipoUsu.tipo = "AFILIADO";
      }else{
        this.tipoUsu.id = 2;
        this.tipoUsu.tipo = "NO AFILIADO";
        this.usuarioExistente = false;
        this.numDocumento = "";
        if(this.numDocumento == "" ||this.nombre == "" || this.apellido == "" || this.correo == ""){
          this.valueHabeas = false;
          this.validacion[19] = false;
          this.validacion[17] = false;
          this.indicador = true;
        }
      }
    }

    if(id == 5 && this.indicador){
      this.indicador = false;
      this.validacion[17] = true;
    }

    if(id == 19){
      if(this.valueHabeas){
        this.validacion[id] = true;
      }else{
        this.validacion[id] = false;
      }
      //validacion de causales
      for(var i = 0; i < this.servicioInconformes.length; i++){
          if(this.servicioInconformes[i].causalSeleccionada == null){
            this.validacion[id] = false;
          }
      }
    }

    if(id == 16 || id == 15){
      this.valueHabeas = false;
      this.validacion[19] = false;
    }

  }

  desactivar(){
    this.validacion = new Array<Boolean>();

    for(let _i = 0;_i<18;_i++){
      this.validacion.push(false);
    }
  }

  addObservacion() : string{
  //let observacionTmp = this.observacion;
    this.texto = "";
    if(!this.nombrePropio){
       this.texto = "Fecha y hora de lo sucedido "+this.fechaHoraEchos+" \n ,Usuario involucrado "+this.nombreRadica+" \n ,Identificado con documento : "+this.documentoRadica+"\n , Sitio de ocurrencia "+this.nombreSitioOcurrencia+",";
    }else{
      this.texto = "Fecha y hora de lo sucedido "+this.fechaHoraEchos+" \n , Sitio de ocurrencia "+this.nombreSitioOcurrencia+",";
    }
    return this.texto;
  }

  activarCampos(){
    for(let i : number = 0;i<11;i++){
      this.validacion[i] = true;
    }
  }

  consultaUsuario(){
    if (this.tipoUsuario != 2) {
      if (this.numContrato.length > 4 && this.numDocumento.length > 4) {
        this.usuarioService.consultarUsuario(this.tipoUsu.id, this.numDocumento, this.numContrato, this.tipoDocumento).subscribe(
          (success) => {
            if (success) {
              this.usuarioExistente = true;
              this.addSingle("Usuario existente", "success");
              this.activarCampos();
            } else {
              this.usuarioExistente = false;
              this.addSingle("Usuario no encontrado", "warn");
            }
          },
          (err) => {
            if (err.status == '404') {
              this.addSingle("Servidor no encontrado " + err.message, "error");
            } else {
              console.log(err);
              this.addSingle("Error " + err.texto, "error");
            }

          }
        )
      }
    }
  } 
 
 vaciarData(){
   this.numContrato="";
 }

 getDependencia(){
   
   this.serviciosInconformes = new Array<SelectItem>(); 
   this.dependencias = new Array<Dependencia>();
   this.servicioInconformes = new Array<any>();
   this.regionalService.getDependencia(this.regionalId).subscribe(
     (success)=>{
      this.dependencias = success;
     },(err)=>{
       console.log(err);
     }
   )
 }

 getClasificacion(){
   for(var i = 0; i < this.dependencias.length; i++){
        if(this.dependencias[i].iddependencia == this.dependenciaId){
          this.nombreSitioOcurrencia = this.dependencias[i].nombre;
          console.log(this.nombreSitioOcurrencia);
        }
   }
  this.serviciosInconformes = new Array<SelectItem>(); 
  this.servicioInconformes = new Array<any>();
  this.regionalService.getClasificacion(this.regionalId,this.dependenciaId).subscribe(
    (success)=>{
      this.serviciosInconformes = new Array<SelectItem>();

      let seleccion : SelectItem;

      success.forEach(element => {
        seleccion = {label:element.nombre,value:{id:element.idclasificacion1,name:element.nombre}};
        this.serviciosInconformes.push(seleccion);
      });
     // console.log(this.serviciosInconformes)
    },(err)=>{
      console.log(err);
    }
    
    );
  }

  confirm1() {
    this.cargarSolicitud();
    console.log(this.solicitud)
    this.confirmationService.confirm({
        message: '¿Estas seguro de proceder?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
            this.registrar();
        },
        reject: () => {
            
        }
    });
}

recargar(){
  location.reload();
}



controlCaptcha(event : any){
  let  capcha = new CaptchaRequest();
  capcha.response = event.response;
  this.recapchaService.getcaptcha(capcha).subscribe(
    (success) =>{
      if(success.success){
        this.activoPaso = true;
      }else{
       this.addSingle("Tiempo agotado por favor recargue","warn"); 
      }
    },(err)=>{
      console.log(err)
    }
  )

}

consultaEstadoTicket(){
  this.activaConsulta = true;
}

consultaEstado(){
  if(this.numTicketConsulta == "" || this.numDocuConsulta == ""){
    this.messageService.add({severity:'warn', summary: 'Consulta estado ticket', detail:'Complete los datos solicitados'});
  }else{
     this.solicitudService.getConsultarEstadoTicket(this.numTicketConsulta, this.numDocuConsulta).subscribe(
      (success) =>{
        this.consultaResultado = true; 
        this.estadoTicket = success;
      },
      (err) =>{
        console.log(err)
        this.messageService.add({severity:'warn', summary: 'Consulta estado ticket', detail: err.error.mensaje});
      }
     )
  }
}

}
