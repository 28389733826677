import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CaptchaResponse } from '../model/CaptchaResponse';
import { Options } from 'selenium-webdriver/edge';
import * as os from 'os';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private url : string;
  private header : any;

  constructor(private http : HttpClient) { 
    this.url = "";

    this.header = new Headers
  }

  getcaptcha(objCaptcha : any
             ){

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    

    this.url = "https://www.google.com/recaptcha/api/siteverify"
    return this.http.post<CaptchaResponse>(this.url,objCaptcha,{headers});
  }



}
