export class TipoUsuario{
    
    public id : number;
    public tipo : string;
    public estado : string;

    constructor(){
        this.id = null;
        this.tipo = "";
        this.estado = "";
    }
}