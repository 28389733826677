import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { FormularioComponent } from './formulario/formulario.component';
import {InputTextModule} from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CalendarModule} from 'primeng/calendar';
import {EditorModule} from 'primeng/editor';
import {FileUploadModule} from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng/api';
import { UtilitiesService } from './share/service/utilities.service';
import { UsuarioService } from './share/service/usuario.service';
import { RegionalService } from './share/service/regional.service';
import { SolicitudService } from './share/service/solicitud.service';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ModalModule } from 'ngx-bootstrap';
import { ToastModule } from 'primeng/toast';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import { AppComponent } from './app.component';
import {CaptchaModule} from 'primeng/captcha';
import { RouterModule } from '@angular/router';
import {TableModule} from 'primeng/table';
import { ChecklistModule } from 'angular-checklist';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  declarations: [
    AppComponent,
    FormularioComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    InputTextModule,
    DropdownModule,
    InputSwitchModule,
    CalendarModule,
    EditorModule,
    FileUploadModule,
    HttpClientModule,
    ConfirmDialogModule,
    ModalModule.forRoot(),
    ToastModule,
    ListboxModule,
    MultiSelectModule,
    CaptchaModule,
    RouterModule.forRoot([]),
    TableModule,
    ChecklistModule,
    ProgressSpinnerModule
    
  ],
  providers: [MessageService,UtilitiesService ,UsuarioService,RegionalService,SolicitudService,ConfirmationService],
  bootstrap: [AppComponent,FormularioComponent]
})
export class AppModule { }
