import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApiConfig {
  
  public urlPqr = "http://formpqr.medplus.com.co:9999"; //Produccion
  //public urlPqr = "http://localhost:9999"; //Desarrollo
  //public urlPqr = "http://172.21.3.34:8888";//Pruebas
  //public urlPqr = "http://localhost:8888"
  //public urlPqr = "http://172.21.0.168:8888"
}