export class Solicitud{

    public tipoUsuario : any;
    public tipoDocumento : any;
    public numDocumento : string;
    public nombre : string;
    public apellido : string;
    public correo : string;
    public dirCorrespondecia : string;
    public departamento : any;
    public ciudad : any;
    public telFijo : string;
    public celular : string;
    public canalRespuesta : any;
    public tipoComentario : any;
    public nombrePropio : Boolean;
    public fechaHoraEchos : string;
    public ciudadEchos : any;
    public servicioInconforme : any;
    public observacion : string;
    public archivoCargados : any;
    public descripcionMedioRecibirInformacion : string;
    public numContrato : string;
    public regionalId : number;
    public dependenciaId : number;
    public medioIngreso : any;
    
}