import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '../config/ApiConfig';
import { TipoRequerimiento } from '../model/TipoRequerimiento';
import { TipoDocumento } from '../model/TipoDocumento';
import { Departamento } from '../model/Departamento';
import { Ciudad } from '../model/Ciudad';
import { CanalRespuesta } from '../model/CanalRespuesta';
import { TipoUsuario } from '../model/TipoUsuario';
import { MedioIngreso } from '../model/MedioIngreso';
import { ListaDesplegable } from '../model/ListaDesplegable';
import { Causal } from '../model/Causal';


@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  
  private url : string;

  constructor(private http : HttpClient,
              private api : ApiConfig)
 {
   this.url = "";
 }

  getTipoRequerimiento(){
    this.url=this.api.urlPqr+"/api/pqr/tipoRequerimiento/2";
    return this.http.get<Array<TipoRequerimiento>>(this.url);
  }

  getTipoDocumento(){
    this.url=this.api.urlPqr+"/api/pqr/tipoDocumento";
    return this.http.get<Array<TipoDocumento>>(this.url);
  }

  getDepartamentos(){
    this.url = this.api.urlPqr+"/api/pqr/Departamentos";
    return this.http.get<Array<Departamento>>(this.url);
  }

  getCiudadByDepartamento(idDepartament : number){
    this.url=this.api.urlPqr+"/api/pqr/ciudad/"+idDepartament;
    return this.http.get<Array<Ciudad>>(this.url);
  }

  getCiudadBynombreDepartamento(nombre : string){
    this.url=this.api.urlPqr+"/api/pqr/ciudad/"+nombre+"/nombre";
    return this.http.get<Array<Ciudad>>(this.url);
  }

  getCanalRespuesta(){
    this.url=this.api.urlPqr+"/api/pqr/canalRespuesta";
    return this.http.get<Array<CanalRespuesta>>(this.url);
  }

  getTipoUsuario(){
    this.url = this.api.urlPqr+"/api/pqr/tipoUsuario";
    return this.http.get<Array<TipoUsuario>>(this.url);
  }

  getMedioCanalIngreso(){
    this.url = this.api.urlPqr+"/api/pqr/medioIngreso";
    return this.http.get<Array<MedioIngreso>>(this.url);
  }

  getDesplegable(data : number){
    this.url = this.api.urlPqr+"/api/pqr/desplegable/"+data;
    return this.http.get<Array<ListaDesplegable>>(this.url);
  }

  getCausales(idClasificacion : string, idCiudad : string, idDependencia : string){
    this.url = this.api.urlPqr+"/api/pqr/"+idClasificacion+"/"+idCiudad+"/"+idDependencia+"/listCausal";
    return this.http.get<Array<Causal>>(this.url);
  }

}


